<template>
  <CabinetPage :preloader="preloader">
    <h3>Ansprechpartner</h3>
  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";

export default {
  components: {CabinetPage},
  data() {
    return {
      preloader: true,
    }
  },
  mounted() {
    setTimeout(this.preload, this.options.preload_time);
  },
  methods: {
    preload() {
      this.preloader = false;
    }
  }
}
</script>
